/**
 * Appointment Planner - Online Appointments REST API
 * Appointment Planner Online Appointments Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ReportsGroupingKeyEnumDTO = 'RESOURCE' | 'USER' | 'TREATMENT' | 'TIME_PERIOD' | 'MEDICAL_SERVICE';

export const ReportsGroupingKeyEnumDTO = {
    RESOURCE: 'RESOURCE' as ReportsGroupingKeyEnumDTO,
    USER: 'USER' as ReportsGroupingKeyEnumDTO,
    TREATMENT: 'TREATMENT' as ReportsGroupingKeyEnumDTO,
    TIMEPERIOD: 'TIME_PERIOD' as ReportsGroupingKeyEnumDTO,
    MEDICALSERVICE: 'MEDICAL_SERVICE' as ReportsGroupingKeyEnumDTO
};

