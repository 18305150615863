import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';

import * as fromProvider from './provider.reducer';
import * as ProviderSelectors from './provider.selectors';

@Injectable()
export class ProviderFacade {
	data$ = this.store.pipe(select(ProviderSelectors.getData));
	messageCount$ = this.store.pipe(select(ProviderSelectors.getCountUnreadMessage));
	systemTemplates$ = this.store.pipe(select(ProviderSelectors.getSystemTemplates));
	customTemplates$ = this.store.pipe(select(ProviderSelectors.getCustomTemplates));
	resourceList$ = this.store.pipe(select(ProviderSelectors.getResources));
	employeeList$ = this.store.pipe(select(ProviderSelectors.getEmployeeResources));
	employeeResourcesObj$ = this.store.pipe(select(ProviderSelectors.getEmployeeResourcesObj));
	roomList$ = this.store.pipe(select(ProviderSelectors.getRoomResources));
	roomResourcesObj$ = this.store.pipe(select(ProviderSelectors.getRoomResourcesObj));
	deviceList$ = this.store.pipe(select(ProviderSelectors.getDeviceResources));
	deviceResourcesObj$ = this.store.pipe(select(ProviderSelectors.getDeviceResourcesObj));
	calendarResources$ = this.store.pipe(select(ProviderSelectors.getCalendarResources));
	settings$ = this.store.pipe(select(ProviderSelectors.getSettings));
	settingsCalendar$ = this.store.pipe(select(ProviderSelectors.getSettingsCalendar));
	loading$ = this.store.pipe(select(ProviderSelectors.getProviderLoading));
	reportFiltersPagination$ = this.store.pipe(select(ProviderSelectors.getReportFiltersPagination));
  userReportFiltersPagination$ = this.store.pipe(select(ProviderSelectors.getUserReportFiltersPagination));
	reportFilters$ = this.store.pipe(select(ProviderSelectors.getReportFilters));
	grouppedAppointmentCounts$ = this.store.pipe(select(ProviderSelectors.grouppedAppointmentCounts));
	totalAppointmentCounts$ = this.store.pipe(select(ProviderSelectors.totalAppointmentCounts));
  reportsUserAppointments$ = this.store.pipe(select(ProviderSelectors.reportsUserAppointments));
  adminInstitutionReportPeriod$ = this.store.pipe(select(ProviderSelectors.adminInstitutionReportPeriod));
	error$ = this.store.pipe(select(ProviderSelectors.getProviderError));

	constructor(private store: Store<fromProvider.ProviderPartialState>) {
	}

	dispatch(action: Action) {
		this.store.dispatch(action);
	}
}
