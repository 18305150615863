/**
 * Roles should cover all pages...
 * Only GOD should help us to understand
 * what will happen with this permissions
 */


const ROLE_PROVIDER_DEFAULT = [
	// APPOINTMENT (only self)
	'CALENDAR_VIEW','CALENDAR_SETTINGS_VIEW', 'MESSAGING_VIEW',
	'CALENDAR_EDIT', 'CALENDAR_SETTINGS_EDIT', 'MESSAGING_EDIT',

	// PROFILE DATA
	'PROFILE_DATA_VIEW', 'OFFICE_HOURS_VIEW', 'EXPERTISE_AREA_VIEW', 'PROFILE_ADDITIONAL_DATA_VIEW',

	// RESOURCE
	'RESOURCE_OVERVIEW_VIEW',
	'RESOURCE_OVERVIEW_EDIT',

	// TREATMENT
	'TREATMENTS_VIEW',

	// OTHER
	'TEXT_TEMPLATES_VIEW',
	'PATIENT_MANAGEMENT_VIEW',
	'PRESCRIPTION_PHOTO_VIEW', 'PRESCRIPTION_PHOTO_EDIT'
];

const ROLE_PROVIDER_PARTIAL = [...ROLE_PROVIDER_DEFAULT,
	// TREATMENT
	'TREATMENTS_EDIT',

	// USERS
	'USERS_MANAGEMENT_VIEW', 'USERS_MANAGEMENT_EDIT'
];

const ROLE_PROVIDER_PATCH = [...ROLE_PROVIDER_DEFAULT,
	'VIEW_ONBOARDING_TOUR',

	// PROFILE DATA
	'PROFILE_DATA_EDIT', 'OFFICE_HOURS_EDIT', 'EXPERTISE_AREA_EDIT', 'PROFILE_ADDITIONAL_DATA_EDIT',

	// TREATMENT
	'TREATMENTS_EDIT',

	// OTHER
	'TEXT_TEMPLATES_EDIT',
	'PATIENT_MANAGEMENT_EDIT',
	'PRESCRIPTION_PHOTO_VIEW', 'PRESCRIPTION_PHOTO_EDIT',
	'USERS_MANAGEMENT_VIEW', 'USERS_MANAGEMENT_EDIT',
  'INSTITUTION_SETTINGS_VIEW', 'INSTITUTION_SETTINGS_EDIT',
	'SERVICES_VIEW', 'SERVICES_EDIT'
];
const ROLE_PROVIDER_FULL = [...ROLE_PROVIDER_PATCH,
	// PROFILE DATA
	'PROFILE_DATA_ADMINISTRATION', 'SERVICES_VIEW', 'SERVICES_EDIT',

	// OTHER
	'INSTITUTION_SETTINGS_VIEW', 'INSTITUTION_SETTINGS_EDIT',
	'DATA_SYNC_VIEW', 'DATA_SYNC_EDIT',
	'MFA_VIEW', 'MFA_EDIT',
];

const ROLE_ADMIN_DEFAULT = [
	// PROVIDER ACCOUNT
	'EXTRA_PROFILE_DATA_CREATE',
	'PROFILE_ACCESS_VIEW', 'PROFILE_ACCESS_EDIT',

	// PROFILE DATA
	'PROFILE_DATA_ADMINISTRATION',
	'PROFILE_DATA_VIEW', 'OFFICE_HOURS_VIEW', 'SERVICES_VIEW', 'EXPERTISE_AREA_VIEW', 'PROFILE_ADDITIONAL_DATA_VIEW',
  'PROFILE_STATISTICS_VIEW',
	'PROFILE_DATA_EDIT', 'OFFICE_HOURS_EDIT', 'SERVICES_EDIT', 'EXPERTISE_AREA_EDIT', 'PROFILE_ADDITIONAL_DATA_EDIT',

	// USER MANAGEMENT
	'USERS_MANAGEMENT_VIEW', 'USERS_MANAGEMENT_EDIT'
];
const ROLE_ADMIN_PATCH = [...ROLE_ADMIN_DEFAULT,
	// USERS
	'USERS_MANAGEMENT_VIEW', 'USERS_MANAGEMENT_EDIT',

	// CONTENT MANAGEMENT SYSTEM
	'CMS_VIEW', 'CMS_EDIT',

	// ONLINE PROFILE MANAGEMENT
	'PROFILE_MANAGEMENT_VIEW', 'PROFILE_MANAGEMENT_EDIT'
];
const ROLE_ADMIN_FULL = [...ROLE_ADMIN_PATCH
];

export const PermissionRole = {
	ROLE_PROVIDER_DEFAULT: ROLE_PROVIDER_DEFAULT,
	ROLE_PROVIDER_PARTIAL: ROLE_PROVIDER_PARTIAL,
	ROLE_PROVIDER_PATCH: ROLE_PROVIDER_PATCH,
	ROLE_PROVIDER_FULL: ROLE_PROVIDER_FULL,
	ROLE_ADMIN_DEFAULT: ROLE_ADMIN_DEFAULT,
	ROLE_ADMIN_PATCH: ROLE_ADMIN_PATCH,
	ROLE_ADMIN_FULL: ROLE_ADMIN_FULL
}
