/**
 * Appointment Planner - Online Appointments REST API
 * Appointment Planner Online Appointments Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The filter for reports user appointments
 */
export type ReportsUserAppointmentsFilterDTO = 'CONFIRMED' | 'NO_SHOW' | 'CANCELED';

export const ReportsUserAppointmentsFilterDTO = {
    CONFIRMED: 'CONFIRMED' as ReportsUserAppointmentsFilterDTO,
    NOSHOW: 'NO_SHOW' as ReportsUserAppointmentsFilterDTO,
    CANCELED: 'CANCELED' as ReportsUserAppointmentsFilterDTO
};

