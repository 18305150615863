import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { HttpClient, HttpHandler, HttpXhrBackend } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { metaReducersDev, metaReducersProd } from '@ap-angular/shared/ngrx';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedAlyleModule, SharedModule } from '@ap-angular/shared/module';
import { SharedComponentModule } from '@ap-angular/shared/component';
import { IsLoggedGuard, LogoutGuard } from '@ap-angular/shared/guard';
import { ServiceUserModule, UserService } from '@ap-angular/service/user';
import { ApplicationService, ServiceApplicationModule } from '@ap-angular/service/application';
import { AnalyticsService, ServiceAnalyticsModule } from '@ap-angular/service/analytics';
import { DialogService, ServiceDialogModule } from '@ap-angular/service/dialog';
import { PathService, ServicePathModule } from '@ap-angular/service/path';
import { MessageService, ServiceMessageModule } from '@ap-angular/service/message';
import { HttpAuthInterceptor, HttpEndpointsModule, httpServiceFactory, RequestOptions } from '@ap-angular/service/http';
import { ProviderService, ServiceProviderModule } from '@ap-angular/service/provider';
import { ServiceWebsocketModule } from '@ap-angular/service/websocket';
import { HashIdService, ServiceHashIdModule } from '@ap-angular/service/hash-id';
import { AdminService, ServiceAdminModule } from '@ap-angular/service/admin';
import { AppointmentService, ServiceAppointmentModule } from '@ap-angular/service/appointment';
import { ContentManagementService, ServiceContentManagementModule } from '@ap-angular/service/content-management';
import { ApiKdsOnboardingModule } from '@ap-angular/api-kds/onboarding';
import { HttpLoaderFactory, InitTranslateFactory, DEFAULT_LANGUAGE } from '@ap-angular/service/translate';

import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';

import { RedirectProviderSyncGoogleGuard } from './redirect-provider-sync-google.guard';

import { AdminAccountComponent } from './admin/account/account.component';
import { AdminProviderHistoryComponent } from './admin/provider-history/provider-history.component';
import { AdminProviderDetailWrapperComponent } from './admin/provider-detail-wrapper/provider-detail-wrapper.component';
import { AdminTemplateComponent } from './admin/template/template.component';

import { ProviderInstitutionSelectComponent } from './provider/institution-select/institution-select.component';
import { ProviderLegalComponent } from './provider/legal/legal.component';
import { ProviderMenuAsideComponent } from './provider/menu-aside/menu-aside.component';
import { ProviderTemplateComponent } from './provider/template/template.component';
import { ProviderWarningsComponent } from './provider/warnings/warnings.component';
import { UI_KEY } from '@ap-angular/service/auth';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { DATEPICKER_FORMATS } from '@ap-angular/shared/schema';

const META_REDUCERS: Array<MetaReducer<any, any>> = environment.production ? metaReducersProd : metaReducersDev;

const devModule: Array<any> = environment.production ? [] : [StoreDevtoolsModule.instrument({
	maxAge: 25, // Retains last 25 states
})];

@NgModule({
	declarations: [
		AppComponent,

		AdminAccountComponent,
		AdminProviderHistoryComponent,
		AdminProviderDetailWrapperComponent,
		AdminTemplateComponent,

		ProviderInstitutionSelectComponent,
		ProviderLegalComponent,
		ProviderMenuAsideComponent,
		ProviderTemplateComponent,
		ProviderWarningsComponent,
	],
	imports: [
		TranslateModule.forRoot({
			defaultLanguage: DEFAULT_LANGUAGE,
			loader: {
				provide: TranslateLoader,
				useFactory: (HttpLoaderFactory),
				deps: [HttpClient]
			},
			isolate: false,
			extend: true
		}),
		StoreModule.forRoot({}, {
			metaReducers: META_REDUCERS,
			runtimeChecks: {
				strictStateImmutability: true,
				strictActionImmutability: true,
				strictStateSerializability: false,
				strictActionSerializability: false,
				strictActionWithinNgZone: true,
				strictActionTypeUniqueness: true
			}
		}),
		EffectsModule.forRoot([]),
		...devModule,

		AppRoutingModule,

		BrowserModule,
		BrowserAnimationsModule,

		HttpEndpointsModule, // Root!!!

		ApiKdsOnboardingModule,

		SharedModule,
		SharedAlyleModule,
		SharedComponentModule,

		ServiceApplicationModule,
		ServiceAnalyticsModule,
		ServiceContentManagementModule,
		ServiceDialogModule,
		ServicePathModule,
		ServiceUserModule,
		ServiceMessageModule,

		ServiceHashIdModule,

		ServiceAdminModule,
		ServiceAppointmentModule,
		ServiceProviderModule,
		ServiceWebsocketModule,

		MatBadgeModule,
		MatButtonModule,
		MatCardModule,
		MatDialogModule,
		MatFormFieldModule,
		MatIconModule,
		MatListModule,
		MatMenuModule,
		MatPaginatorModule,
		MatTableModule,
		MatTabsModule,
		MatToolbarModule,
		MatTooltipModule,
		MatSidenavModule,

		MatDatepickerModule,
	  MomentDateModule
	],
	exports: [
		TranslateModule
	],
	providers: [
		{ provide: 'ENVIRONMENT', useValue: environment },
		{ provide: UI_KEY, useValue: 'admin-ui' },
		{ provide: MAT_DATE_FORMATS, useValue: DATEPICKER_FORMATS },
		{
			provide: APP_INITIALIZER,
			useFactory: InitTranslateFactory,
			deps: [TranslateService, Injector],
			multi: true
		},
		{
			provide: HttpClient,
			useFactory: httpServiceFactory,
			deps: [HttpXhrBackend, HttpAuthInterceptor, RequestOptions, HttpHandler]
		},

		MatIconRegistry,

		IsLoggedGuard,
		LogoutGuard,
		RedirectProviderSyncGoogleGuard,

		ApplicationService,
		AnalyticsService,
		DialogService,
		PathService,
		UserService,
		MessageService,

		AdminService,
		AppointmentService,
		ContentManagementService,
		ProviderService,
		HashIdService
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
