/**
 * Appointment Planner - Online Appointments REST API
 * Appointment Planner Online Appointments Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TimePeriodEnumDTO = 'YEAR' | 'MONTH' | 'WEEK';

export const TimePeriodEnumDTO = {
    YEAR: 'YEAR' as TimePeriodEnumDTO,
    MONTH: 'MONTH' as TimePeriodEnumDTO,
    WEEK: 'WEEK' as TimePeriodEnumDTO
};

