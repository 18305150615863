import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { SharedMatFormModule } from '@ap-angular/shared/module';
import { SharedDirectiveModule } from '@ap-angular/shared/directive';

import { AvatarComponent } from './avatar/avatar.component';
import { BufferTimeComponent } from './buffer-time/buffer-time.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { ContactComponent } from './contact/contact.component';
import { ContentDisplayComponent } from './content-display/content-display.component';
import { ErrorComponent } from './error/error.component';
import { LoaderComponent } from './loader/loader.component';
import { LogoComponent } from './logo/logo.component';
import { ProviderProfileStatusComponent } from './provider-profile-status/provider-profile-status.component';
import { RedirectComponent } from './redirect/redirect.component';
import { SearchingComponent } from './searching/searching.component';
import { CountryPrefixComponent } from './country-prefix/country-prefix.component';
import { ContactShortComponent } from './contact-short/contact-short.component';
import { ToggleSelectComponent } from './toggle-select/toggle-select.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { LogoToolbarComponent } from './logo-toolbar/logo-toolbar.component';
import { FiltersComponent } from './filters/filters.component';
import { LabelComponent } from './label/label.component';
import { MultiSelectComponent } from './multiselect-autocomplete/multiselect-autocomplete.component';
import {
  ProviderReportStatisticsCardComponent
} from '../../../../api/provider-report/src/lib/report/statistics/statistics-card.component';

@NgModule({
	declarations: [
		AvatarComponent,
		BufferTimeComponent,
		ColorPickerComponent,
		ContactComponent,
		ContactShortComponent,
		ContentDisplayComponent,
		CountryPrefixComponent,
		ErrorComponent,
		LoaderComponent,
		LogoComponent,
		ProviderProfileStatusComponent,
		RedirectComponent,
		SearchingComponent,
		RedirectComponent,
		ToggleSelectComponent,
		LogoToolbarComponent,
  	FiltersComponent,
		LabelComponent,
    MultiSelectComponent,
    ProviderReportStatisticsCardComponent
	],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,

    SharedMatFormModule,
    SharedDirectiveModule,

    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatMenuModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatTooltipModule,
    MatSlideToggleModule
  ],
	exports: [
		AvatarComponent,
		BufferTimeComponent,
		ColorPickerComponent,
		ContactComponent,
		ContactShortComponent,
		CountryPrefixComponent,
		LoaderComponent,
		LogoComponent,
		ProviderProfileStatusComponent,
		RedirectComponent,
		SearchingComponent,
		ToggleSelectComponent,
		LogoToolbarComponent,
		FiltersComponent,
		LabelComponent,
    MultiSelectComponent,
    ProviderReportStatisticsCardComponent,

		MatButtonModule,
		MatDialogModule,
		MatIconModule,
		MatMenuModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatToolbarModule,
	]
})
export class SharedComponentModule {
	static forRoot(): ModuleWithProviders<SharedComponentModule> {
		return {
			ngModule: SharedComponentModule
		};
	}
}
