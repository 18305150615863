/**
 * Appointment Planner - Care Providers REST API
 * Appointment Planner Care Providers Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ResourceHoursRequestDTO { 
    careProviderId: number;
    resourceIds: Array<number>;
    startDate: string;
    interval: number;
    /**
     * Insurance type
     */
    insuranceType?: ResourceHoursRequestDTO.InsuranceTypeEnum;
    standardSchedulePeriod?: boolean;
    onlineSchedulePeriod?: boolean;
    internalSchedulePeriod?: boolean;
    absentHours?: boolean;
}
export namespace ResourceHoursRequestDTO {
    export type InsuranceTypeEnum = 'PRIVATE' | 'PUBLIC';
    export const InsuranceTypeEnum = {
        PRIVATE: 'PRIVATE' as InsuranceTypeEnum,
        PUBLIC: 'PUBLIC' as InsuranceTypeEnum
    };
}


