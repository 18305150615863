export * from './classicAppointment.service';
import { ClassicAppointmentService } from './classicAppointment.service';
export * from './deletionHistory.service';
import { DeletionHistoryService } from './deletionHistory.service';
export * from './externalCalendar.service';
import { ExternalCalendarService } from './externalCalendar.service';
export * from './kpi.service';
import { KpiService } from './kpi.service';
export * from './message.service';
import { MessageService } from './message.service';
export * from './oAInternal.service';
import { OAInternalService } from './oAInternal.service';
export * from './onlineAppointment.service';
import { OnlineAppointmentService } from './onlineAppointment.service';
export * from './patientDetails.service';
import { PatientDetailsService } from './patientDetails.service';
export * from './patientWaitingList.service';
import { PatientWaitingListService } from './patientWaitingList.service';
export * from './reports.service';
import { ReportsService } from './reports.service';
export * from './treatment.service';
import { TreatmentService } from './treatment.service';
export * from './waitingList.service';
import { WaitingListService } from './waitingList.service';
export const APIS = [ClassicAppointmentService, DeletionHistoryService, ExternalCalendarService, KpiService, MessageService, OAInternalService, OnlineAppointmentService, PatientDetailsService, PatientWaitingListService, ReportsService, TreatmentService, WaitingListService];
